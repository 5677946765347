import node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq from "/var/www/html/previewtec.biz.id/prd_v2/shvr2024-fe/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import _nuxt_components_plugin_mjs_KR1HBZs4kY from "/var/www/html/previewtec.biz.id/prd_v2/shvr2024-fe/.nuxt/components.plugin.mjs";
import node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0 from "/var/www/html/previewtec.biz.id/prd_v2/shvr2024-fe/node_modules/nuxt/dist/head/runtime/lib/vueuse-head.plugin.mjs";
import node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB from "/var/www/html/previewtec.biz.id/prd_v2/shvr2024-fe/node_modules/nuxt/dist/pages/runtime/router.mjs";
import node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_OrkQhMqHci from "/var/www/html/previewtec.biz.id/prd_v2/shvr2024-fe/node_modules/@nuxt/image-edge/dist/runtime/plugin.mjs";
import plugins_api_ts_GFfDXud5Cr from "/var/www/html/previewtec.biz.id/prd_v2/shvr2024-fe/plugins/api.ts";
import plugins_auth_ts_vT9JWWT9pN from "/var/www/html/previewtec.biz.id/prd_v2/shvr2024-fe/plugins/auth.ts";
export default [
  node_modules__64pinia_nuxt_dist_runtime_plugin_vue3_mjs_A0OWXRrUgq,
  _nuxt_components_plugin_mjs_KR1HBZs4kY,
  node_modules_nuxt_dist_head_runtime_lib_vueuse_head_plugin_mjs_D7WGfuP1A0,
  node_modules_nuxt_dist_pages_runtime_router_mjs_qNv5Ky2ZmB,
  node_modules__64nuxt_image_edge_dist_runtime_plugin_mjs_OrkQhMqHci,
  plugins_api_ts_GFfDXud5Cr,
  plugins_auth_ts_vT9JWWT9pN
]